.creator {
    font-size: 12px !important;
}

.completed,
.completed.list-group-item-action:focus {
    background-color: lightgreen;
}

.completed:hover {
    background-color: rgba(144, 238, 144, 0.8);
}

.high {
    color: green;
}

.normal {
    color: blue;
}

.low {
    color: orange;
}

.toggle:hover {
    color: green;
}

.edit:hover {
    color: orange;
}

.delete:hover {
    color: red;
}