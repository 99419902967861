body, html {
    height: 100%;
    background-color: #ecf0f5;
}

.row {
    margin-left: 0;
    margin-right: 0;
}

.login-box {
    margin: 10% auto;
}

.table {
    font-size: 20px;
}

.table-list {
    margin: 1rem 0;
}

.table-list > div {
    border: 2px solid #ecf0f5;
    padding: .5rem;
}

.table-list > div:first-child {
    border-radius: 10px 10px 0 0;
}

.table-list > div:last-child {
    border-radius: 0 0 10px 10px;
}

.table-list > div:not(:last-child) {
    border-bottom: none;
}

.table-list p {
    margin-top: .5rem;
    margin-bottom: .5rem;
}

.table-list button {
    margin-top: .25rem;
}

/* Mobile Stuff */
.mobile-view .flex-with-buttons {
    flex-wrap: wrap;
    justify-content: center;
}

.mobile-view .flex-with-buttons > * {
    width: 100%;
    text-align: center;
}

.mobile-view .mobile-center {
    text-align: center;
}