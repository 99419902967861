@media print{
    @page {
        size: landscape;
    }
}

.covidCalendarContainer {
    overflow-y: scroll;
}

.covidMonth {
    border: 1px solid black;
}

.covidMonth .calendarDay:not(:last-child) {
    border-bottom: 1px solid black;
}

.calendarDay {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.calendarDay .day {
    width: 300px;
    font-weight: bold;
    padding: 10px;
}

.calendarDay .names {
    width: 100%;
    font-weight: bold;
    padding: 10px;
    border-left: 1px solid black;
}

.calendarDay .name {
    margin: 0 10px;
}


.claimed {
    background-color: lightyellow;
}

.claimedOwn {
    background-color: lightgreen;
}
