.seatingPlan {
    text-align: center;
}

.seatingRow {
    width: 100%;
    border-top: #000 1px solid;
    display: flex;
}

.seatingRow:last-child {
    border-bottom: #000 1px solid;
}

.rowName,
.seat {
    width: 100%;
    text-align: center;
    border-left: #000 1px solid;
    padding: 1rem 0;
    cursor: pointer;
}

.rowName {
    font-weight: bold;
}

.seat:last-child {
    border-right: #000 1px solid;
}

.taken-seat {
    background-color: gray;
}

.selected-seat {
    background-color: green;
}

.manual-seat {
    background-color: orange;
}

.arrived {
    color: greenyellow;
    font-weight: bold;
}

.reservationList {
    height: 80vh;
    overflow-y: scroll;
}

.reservationList .reservation {
    border-bottom: #000 1px solid;
    padding: .25rem;
}

.reservation.selected {
    background-color: rgba(0, 255, 0, 0.2);
}

.unpaid,
.missingPlaced {
    color: red;
}

.wheelchair {
    color: blue;
}

.subscription {
    color: darkgoldenrod;
}